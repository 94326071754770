import React, {useState, useRef, useEffect} from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom';
import './update.scss'

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import API_URL from '../../API/Api';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

const Update = () => {
  const navigate = useNavigate();

  let { countryId } = useParams();

  let [country, setCountry] = useState({})
  const name = useRef('');
  const picture = useRef('');
  const token = localStorage.getItem('token');

  const fetchData = () => {
    fetch(`${API_URL}/api/country/${countryId}`, {
        method: 'GET',
        headers : {
          'Authorization': 'Bearer ' + token,
        }
      })
        .then(response => response.json())
        .then(response => setCountry(response))
        .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchData();
  }, [])

  const handleSumbit = (event) => {
        event.preventDefault();

        const postData = {
            name: name.current.value,
            picture: picture.current.value,
        };
        console.log(postData)

        fetch(`${API_URL}/api/country/${countryId}`, {
            method: 'PUT',
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(postData)
        } )
            .then(response => response.json())
            .then(response => setCountry(response))

        navigate('/country');
  }

  return (
    <div className='update'>
        <Sidebar />
        <div className="updateContainer">
            <Navbar />
            <div className="form">
                <div className="top">
                    <h1 className="title">Update : {country.name}</h1>
                    <div className="updateButton">
                        <ArrowBackIosNewOutlinedIcon className='icon' />
                        <Link 
                            to={{
                                pathname: `/country`
                            }} 
                            className='link'
                        >
                            Return to the Countrie's list
                        </Link>
                    </div>
                </div>
                <form onSubmit={handleSumbit}>
                    <label>
                        <span>Name</span>
                        <input
                            name="name"
                            type="text"
                            placeholder='Name'
                            defaultValue={country.name}
                            ref={name}
                        />
                    </label>
                    <br />
                    <label>
                        <span>Picture</span>
                        <input
                            name="picture"
                            type="text"
                            placeholder='Picture (URL)'
                            defaultValue={country.picture}
                            ref={picture}
                        />
                    </label>
                    <button type='submit'>Update</button>
                </form>
                <div className="required">
                    Required fields
                </div>
            </div>
        </div>
    </div>
  )
}

export default Update