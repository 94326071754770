import React, {useState, useEffect} from 'react'
import { useParams, Link } from 'react-router-dom';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import './single.scss'

import API_URL from '../../API/Api';

import Spinner from "react-activity/dist/Dots";
import "react-activity/dist/library.css";
import parse from 'html-react-parser';

const SingleSpot = () => {
  let { spotId } = useParams();

  const [spot, setSpot] = useState([]);
  let [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');
  
  const fetchData = () => {
    setLoading(true)
    fetch(`${API_URL}/api/spot/${spotId}`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(response => response.json())
      .then(response => setSpot(response))
      .catch(error => console.log(error))
      .finally( () => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div className='single'>
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        {
          loading ? (
            <Spinner className='spinner' />
          ) : (
            <div className="top">
              <div className="editButton">
                <Link
                  to={{
                    pathname: `update`
                  }}
                  className='link'
                >
                  Edit
                </Link>
              </div>

              <h1 className="title">Information on {spot.name}</h1>

              <div className="return">
                <Link
                  to={{
                    pathname: `/spot`
                  }}
                  className='link'
                >
                  Return to the spot's list
                </Link>
              </div>
              
              <div className="item">
                <img 
                  src={spot.picture}
                  alt={spot.name} 
                  className="itemImg" 
                />
                <div className="details">
                  <div className="detail">
                    <h2 className="itemTitle">{spot.name}</h2>
                    <p>{spot.city?.name}</p>
                    <p><strong>Price</strong> : {spot.price} €</p>
                    <p><strong>Time</strong> : {spot.time} minutes</p>
                    <p><strong>Catégorie</strong> : {spot.category}</p>
                    <p><a href={spot.itinerary} target="_blank" rel='noreferrer' className='link'>Itinerary</a></p>
                  </div>
                  
                  <div className="checkbox">
                    <p><strong>Bestview</strong> : {spot.bestView === true ? '✅' : '❌'}</p>
                    <p><strong>Family</strong> : {spot.family === true ? '✅' : '❌'}</p>
                    <p><strong>Activity</strong> : {spot.activity === true ? '✅' : '❌'}</p>
                    <p><strong>Visit</strong> : {spot.visit === true ? '✅' : '❌'}</p>
                    <p><strong>Trek</strong> : {spot.trek === true ? '✅' : '❌'}</p>
                    <p><strong>Meal</strong> : {spot.meal === true ? '✅' : '❌'}</p>
                    <p><strong>Transport</strong> : {spot.transport === true ? '✅' : '❌'}</p>
                    <p><strong>Toilet</strong> : {spot.toilet === true ? '✅' : '❌'}</p>
                  </div>

                  <div className="description">
                    {/* <p>{spot.text}</p> */}
                    {parse(`${spot.text}`)}
                  </div>

                  <div className='youtube'>
                    {
                      spot.link !== ''
                      ?
                        <p>
                          Lien de la vidéo Youtube
                          <a href={spot.link} target="_blank" rel='noreferrer'>Vidéo</a>
                        </p>
                      :
                        ''
                    }
                  </div>

                  <div className='slider'>
                    {
                      spot.slider.length > 0
                      ?
                        spot.slider?.map( picture => {
                          return(
                            <>
                              <p>
                                <a href={picture.url} target="_blank" rel='noreferrer'>{picture.name}</a>
                              </p>
                            </>
                          )
                        })
                      :
                        ''
                    }
                  </div>

                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default SingleSpot