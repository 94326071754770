import React, {useState, useEffect, useRef} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import './add.scss'

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import API_URL from '../../API/Api';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

import Spinner from "react-activity/dist/Dots";
import "react-activity/dist/library.css";


const AddCity = () => {

  const navigate = useNavigate();

  let [all, setAll] = useState({})
  let [city, setCity] = useState({})
  let [loading, setLoading] = useState(true)
  let [options, setOptions] = useState([])
  const name = useRef('');
  const picture = useRef('');
  const countryId = useRef('');
  const token = localStorage.getItem('token');



  /// function getCountriesOptions()
  const getCountriesOptions = (countries) => {
    const countriesOptions = countries.map(item => {
        return (<option key={item.id} value={item.id}>{item.name}</option>)
    });
    console.log(countriesOptions);
    return countriesOptions;
  }

  /// function fetchData()
  const fetchData = () => {
      setLoading(true)
      fetch(`${API_URL}/api/countries`, {
        method: 'GET',
        headers : {
          'Authorization': 'Bearer ' + token,
        }
      })
        .then(response => response.json())
        .then((response) => {
            const cityOptions = getCountriesOptions(response);
            setOptions(cityOptions);
        })
        .catch(error => console.log(error))
        .finally(() => {
            setLoading(false);
        });
  }



  const handleSumbit = (event) => {
    event.preventDefault();

    const postData = {
        name: name.current.value,
        picture: picture.current.value,
        countryId: countryId.current.value
    };
    console.log(postData)

    fetch(`https://destinationautourdumonde.fr/api/public/api/city`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(postData)
    } )
        .then(response => response.json())
        //.then(response => console.log(response))
        .then(response => setCity(response))

    navigate('/dashboard');
  }

  useEffect(() => {
    fetchData();
  }, []);



  return (
    <div className='add'>
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1 className='title'>Add New City</h1>
          <div className="updateButton">
              <ArrowBackIosNewOutlinedIcon className='icon' />
              <Link 
                  to={{
                      pathname: `/city`
                  }} 
                  className='link'
              >
                  Return to the Cities's list
              </Link>
          </div>
        </div>
        {
          loading ? (
            <Spinner className='spinner' />
          ) : (
            <div className="bottom">
              <form onSubmit={handleSumbit}>
                <div className="width100">
                  <label>
                    <span>Name</span>
                    <input
                        name="name"
                        type="text"
                        placeholder='Name'
                        ref={name}
                    />
                  </label>
                </div>
                <div className="width100">
                  <label>
                    <span>Picture</span>
                    <input
                        name="picture"
                        type="text"
                        placeholder='Picture (URL)'
                        ref={picture}
                    />
                  </label>
                </div>
                <div className="width100">
                  <label>
                    <span>Choose Country</span>
                    {
                      loading ? (
                        <Spinner />
                      ) : (
                        <select name="" id="" ref={countryId}>
                          <option value="">Choose a country</option>
                          {
                            options
                          }
                        </select>
                      )
                    }
                  </label>
                </div>
                <button type='submit'>Add</button>
              </form>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default AddCity