import React, {useState, useEffect} from 'react'
import { useParams, Link } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import './single.scss'

import API_URL from '../../API/Api';

import Spinner from "react-activity/dist/Dots";
import "react-activity/dist/library.css";

const SingleCity = () => {
  let { cityId } = useParams();

  const [city, setCity] = useState([]);
  let [loading, setLoading] = useState(true)
  const token = localStorage.getItem('token')
  
  const fetchData = () => {
    setLoading(true)
    fetch(`${API_URL}/api/city/${cityId}`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(response => response.json())
      .then(response => setCity(response))
      .catch(error => console.log(error))
      .finally( () => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div className='single'>
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        {
          loading ? (
            <Spinner className='spinner' />
          ) : (
            <div className="top">
              <div className="editButton">
                <Link
                  to={{
                    pathname: `update`
                  }}
                  className='link'
                >
                  Edit
                </Link>
              </div>

              <h1 className="title">Information on {city.name}</h1>

              <div className="return">
                <Link
                  to={{
                    pathname: `/city`
                  }}
                  className='link'
                >
                  Return to the citie's list
                </Link>
              </div>
              
              <div className="item">
                <img 
                  src={city.picture}
                  alt="test" 
                  className="itemImg" 
                />
                <div className="details">
                  <h2 className="itemTitle">{city.name}</h2>
                  <p>{city.country?.name}</p>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default SingleCity