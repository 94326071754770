import React from 'react'
import Login from '../login/Login'
import "./admin.scss"

function Admin() {
  return (
    <div className="admin">
        <div className="top">
            <span className="logo">Destination</span>
            <div className="items">
                <a href="/" className='link'>Login</a>
            </div>
        </div>
        <div className="bottom">
            <Login />
        </div>
    </div>
  )
}

export default Admin