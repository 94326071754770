import React, {useState, useRef, useEffect} from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import './update.scss'

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import API_URL from '../../API/Api';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

import Spinner from "react-activity/dist/Dots";
import "react-activity/dist/library.css";

const UpdateSpot = () => {
  const navigate = useNavigate();

  let { spotId } = useParams();

  let [spot, setSpot] = useState({});
  let [loading, setLoading] = useState(true);
  let [options, setOptions] = useState();
  const token = localStorage.getItem('token');

  const name = useRef('');
  const picture = useRef('');
  const cityId = useRef('');
  const price = useRef(0);
  const time = useRef(0);
  const text = useRef('');
  const itinerary = useRef('');
  const category = useRef('');
  const likes = useRef(0);
  const bestView = useRef();
  const family = useRef();
  const activity = useRef();
  const visit = useRef();
  const trek = useRef();
  const meal = useRef();
  const transport = useRef();
  const toilet = useRef();
  const link = useRef('');

  const getCitiesOptions = (cities) => {
    const citiesOptions = cities.map(item => {
        return (<option key={item.id} value={item.id}>{item.name}</option>)
    });
    return citiesOptions;
  }

  const fetchData = () => {
    setLoading(true)
    fetch(`${API_URL}/api/spot/${spotId}`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(response => response.json())
      .then((response) => {
          setSpot(response);
      })
      .catch(error => console.log(error))
      .finally(() => {
          setLoading(false);
      });
    fetch(`${API_URL}/api/cities`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(response => response.json())
      .then((response) => {
          const spotOptions = getCitiesOptions(response);
          setOptions(spotOptions);
      })
      .catch(error => console.log(error))
      .finally(() => {
          setLoading(false);
      });
  }

  const handleSumbit = (event) => {
    event.preventDefault();

    const postData = {
        name: name.current.value,
        picture: picture.current.value,
        cityId: cityId.current.value,
        price: price.current.value,
        time: time.current.value,
        text: text.current.getContent(),
        itinerary: itinerary.current.value,
        category: category.current.value,
        likes: likes.current.value,
        bestView: bestView.current.checked,
        family: family.current.checked,
        activity: activity.current.checked,
        visit: visit.current.checked,
        trek: trek.current.checked,
        meal: meal.current.checked,
        transport: transport.current.checked,
        toilet: toilet.current.checked,
        link: link.current.value,
    };
    console.log(postData)

    fetch(`${API_URL}/api/spot/${spotId}`, {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(postData)
    } )
      .then(response => response.json())
      .then(response => setSpot(response))

    navigate('/spot');
  }

  useEffect(() => {
    fetchData();
  }, []);

  const initialText = `${spot.text}`
  return (
    <div className='update'>
        <Sidebar />
        <div className="updateContainer">
            <Navbar />
            {
                loading ? (
                    <Spinner className='spinner' />
                ) : (
                    <div className="form">
                        <div className="top">
                            <h1 className="title">Update</h1>
                            <div className="updateButton">
                                <ArrowBackIosNewOutlinedIcon className='icon' />
                                <Link 
                                    to={{
                                        pathname: `/spot`
                                    }} 
                                    className='link'
                                >
                                    Return to the Spot's list
                                </Link>
                            </div>
                        </div>
                        <form onSubmit={handleSumbit}>
                          <fieldset>
														<legend>Informations Principales</legend>
														<label>
                              <span>Name</span>
                              <input
																name="name"
																type="text"
																placeholder='Name'
																defaultValue={spot.name}
																ref={name}
                              />
														</label>
														<label>
															<span>Picture</span>
															<input
																name="picture"
																type="text"
																placeholder='Picture (URL)'
																defaultValue={spot.picture}
																ref={picture}
															/>
														</label>
														<label>
															<span>Price</span>
															<input
																name="price"
																type="number"
																placeholder='Price of the spot'
																defaultValue={spot.price}
																ref={price}
															/>
														</label>
														<label>
															<span>Time</span>
															<input
																name="time"
																type="number"
																placeholder='Time of the spot'
																defaultValue={spot.time}
																ref={time}
															/>
														</label>
														<label>
															<span>Itinerary</span>
															<input
																name="itinerary"
																type="text"
																placeholder='Itinerary (URL)'
																defaultValue={spot.itinerary}
																ref={itinerary}
															/>
														</label>
														<label>
															<span>Likes</span>
															<input
																name="likes"
																type="number"
																placeholder='Likes of the spot'
																defaultValue={spot.like}
																ref={likes}
															/>
														</label>
                            <label>
                              <span>Link Youtube</span>
                              <input
                                  name="link"
                                  type="text"
                                  placeholder='Link embed to Youtube'
                                  defaultValue={spot.link}
                                  ref={link}
                              />
                            </label>
														<label>
                              <span>Choose City : {spot.city?.name}</span>
                              {
                                loading ? (
                                  <Spinner />
                                ) : (
                                  <select name="city" id="city" defaultValue={spot.city?.id} ref={cityId} required>
                                    <option value="">Choose a city</option>
                                    {
                                      options
                                    }
                                  </select>
                                )
                              }
                          	</label>
														<label>
															<span>Category</span>
															<select name="category" id="category" defaultValue={spot.category} ref={category}>
																<option value="Visite">Visite</option>
																<option value="Point de vue">Point de vue</option>
																<option value="Shopping">Shopping</option>
																<option value="Découverte">Découverte</option>
																<option value="Promenade">Promenade</option>
																<option value="Plage">Plage</option>
																<option value="Parc Animalier">Parc Animalier</option>
																<option value="Parc d'Attraction">Parc d'Attraction</option>
																<option value="Restaurant">Restaurant</option>
															</select>
														</label>
													</fieldset>
                          <fieldset className="checkbox">
														<legend>Checkbox</legend>
														<label>
															<input
																name="bestView"
																type="checkbox"
																placeholder="It's a bestview ?"
																defaultChecked={spot.bestView===true ? true : false}
																ref={bestView}
															/> BestView
														</label>
														<label>
															<input
																name="family"
																type="checkbox"
																placeholder="It's for the family ?"
																defaultChecked={spot.family!==true ? false : true}
																ref={family}
															/> Family
														</label>
														<label>
															<input
																name="activity"
																type="checkbox"
																placeholder="It's an activity ?"
																defaultChecked={spot.activity!==true ? false : true}
																ref={activity}
															/> Activity
														</label>
														<label>
															<input
																name="visit"
																type="checkbox"
																placeholder="It's a visit ?"
																defaultChecked={spot.visit!==true ? false : true}
																ref={visit}
															/> Visit
														</label>
														<label>
															<input
																name="trek"
																type="checkbox"
																placeholder="It's a trek ?"
																defaultChecked={spot.trek!==true ? false : true}
																ref={trek}
															/> Trek
														</label>
														<label>
															<input
																name="meal"
																type="checkbox"
																defaultValue={spot.meal!==true ? false : true}
																ref={meal}
															/> Meal
														</label>
														<label>
															<input
																name="transport"
																type="checkbox"
																placeholder="Transport is include ?"
																defaultChecked={spot.transport!==true ? false : true}
																ref={transport}
															/> Transport
														</label>
														<label>
															<input
																name="toilet"
																type="checkbox"
																placeholder="Toilet is include ?"
																defaultChecked={spot.toilet!==true ? false : true}
																ref={toilet}
															/> Toilet
														</label>
													</fieldset>
                          <label className='textarea'>
                              <span>Text</span>
                              {/* <textarea ref={text} name="text" placeholder='Description' defaultValue={spot.text} /> */}
                              <Editor
                                apiKey='l4grsk94s5o1x52ye4egnqncyxrftsti2ux368r3e0pqu482'
                                onInit={(evt, editor) => text.current = editor}
                                initialValue={initialText}
                                init={{
                                  height: 500,
                                  menubar: false,
                                  selector: 'textarea',
                                  plugins: 'lists advlist',
                                  toolbar: 'bullist | bold | italic | alignleft | aligncenter',
                                  advlist_bullet_styles: 'disc',
                                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                              />
                          </label>
                          <button type='submit'>Update Spot</button>
                      </form>
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default UpdateSpot