import React, {useState, useEffect} from 'react'
import './datatable.scss'
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";

import API_URL from '../../API/Api';

import Spinner from "react-activity/dist/Dots";
import "react-activity/dist/library.css";



const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Name', width: 230 },
  { 
      field: 'picture', 
      headerName: 'Picture', 
      width: 300,
      renderCell: (params) => {
          return(
            <img 
              src={params.row.picture} 
              alt={params.row.name} 
            />
          )
      }
  },
  {
    field: "action",
    headerName: "Action",
    width: 350,
    renderCell: (params) => {
        return(
          <div className="cellAction">
              <div className="viewButton">
                <Link 
                    to={{
                        pathname: `/city/${params.row.id}`
                    }} 
                    className='link'
                >
                 View
                </Link>
              </div>
              <div className="updateButton">
                <Link 
                    to={{
                        pathname: `/city/${params.row.id}/update`
                    }} 
                    className='link'
                >
                 Update
                </Link>
              </div>
              <div className="deleteButton" onClick={(event) => {
                event.preventDefault();

                let res = window.confirm("Etes vous sur de supprimer l'élément ?");
                //console.log(res)
                if (res === true) 
                {
                  fetch(`${API_URL}/api/city/${params.row.id}`, {
                      method: 'DELETE'
                  } )
                      .then(response => response.json())

                  window.location.reload();
                }
                
              }}>
                Delete
              </div>
          </div>
        )
    }
  },
];

const DatatableCity = () => {

    const [rows, setRows] = useState(true);
    let [loading, setLoading] = useState(true);
    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchData();
    }, [])
  
    const fetchData = () => {
      setLoading(true)
        fetch(`${API_URL}/api/cities`, {
          method: 'GET',
          headers : {
            'Authorization': 'Bearer ' + token,
          }
        })
          .then(response => response.json())
          .then(response => setRows(response))
          .catch(error => console.log(error))
          .finally(() => {
            setTimeout( () => {
              setLoading(false);
            }, 1000)
          });
    }


  return (
    <div className='datatable'>
      <a href="/city/add" className='addLink'>
        Add City
      </a>
      {
        loading ? (
          <Spinner className='spinner'/>
        ) : (
          <DataGrid
            className='datagrid'
            rows={rows}
            columns={columns}
            pageSize={7}
            rowsPerPageOptions={[7]}
            checkboxSelection
          />
        )
      }
    </div>
  )
}

export default DatatableCity