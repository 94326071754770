import React, {useState, useEffect} from 'react'
import './datatable.scss'
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";

import API_URL from '../../API/Api';



const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Name', width: 230 },
  { 
      field: 'picture', 
      headerName: 'Picture', 
      width: 300,
      renderCell: (params) => {
          return(
            <img 
              src={params.row.picture} 
              alt={params.row.name} 
            />
          )
      }
  },
  {
    field: "action",
    headerName: "Action",
    width: 350,
    renderCell: (params) => {
        return(
          <div className="cellAction">
              <div className="viewButton">
                <Link 
                    to={{
                        pathname: `/country/${params.row.id}`
                    }} 
                    className='link'
                >
                 View
                </Link>
              </div>
              <div className="updateButton">
                <Link 
                    to={{
                        pathname: `/country/${params.row.id}/update`
                    }} 
                    className='link'
                >
                 Update
                </Link>
              </div>
              <div className="deleteButton" onClick={(event) => {
                event.preventDefault();

                let res = window.confirm("Etes vous sur de supprimer l'élément ?");
                //console.log(res)
                if (res === true) 
                {
                  fetch(`${API_URL}/api/country/${params.row.id}`, {
                      method: 'DELETE'
                  } )
                      .then(response => response.json())

                  window.location.reload();
                }
                
              }}>
                Delete
              </div>
          </div>
        )
    }
  },
];

const Datatable = () => {

    const [rows, setRows] = useState(true);
    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchData();
    }, [])
  
    const fetchData = () => {
        fetch(`${API_URL}/api/countries`, {
          method: 'GET',
          headers : {
            'Authorization': 'Bearer ' + token,
          }
        })
          .then(response => response.json())
          .then(response => setRows(response))
          .catch(error => console.log(error))
    }

  return (
    <div className='datatable'>
      <a href="/country/add" className='addLink'>
        Add Country
      </a>
      <DataGrid
        className='datagrid'
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </div>
  )
}

export default Datatable