import React, {useState, useEffect, useRef} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import './add.scss'

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import API_URL from '../../API/Api';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

import Spinner from "react-activity/dist/Dots";
import "react-activity/dist/library.css";


const AddSpot = () => {

  const navigate = useNavigate();

  let [all, setAll] = useState({})
  let [spot, setSpot] = useState({})
  let [loading, setLoading] = useState(true)
  let [options, setOptions] = useState([])
  const token = localStorage.getItem('token')

  const name = useRef('');
  const picture = useRef('');
  const cityId = useRef('');
  const price = useRef(0);
  const time = useRef(0);
  const text = useRef('');
  const itinerary = useRef('');
  const category = useRef('');
  const likes = useRef(0);
  const bestView = useRef();
  const family = useRef();
  const activity = useRef();
  const visit = useRef();
  const trek = useRef();
  const meal = useRef();
  const transport = useRef();
  const toilet = useRef();
  const link = useRef('');



  /// function getCitiesOptions()
  const getCitiesOptions = (cities) => {
    const citiesOptions = cities.map(item => {
        return (<option key={item.id} value={item.id}>{item.name}</option>)
    });
    console.log(citiesOptions);
    return citiesOptions;
  }

  /// function fetchData()
  const fetchData = () => {
    setLoading(true)
    fetch(`${API_URL}/api/all`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(response => response.json())
      .then((response) => {
          setAll(response);
          const spotsOptions = getCitiesOptions(response.Cities);
          setOptions(spotsOptions);
      })
      .catch(error => console.log(error))
      .finally(() => {
          setLoading(false);
      });
  }

  const handleSumbit = (event) => {
    event.preventDefault();

    const postData = {
        name: name.current.value,
        picture: picture.current.value,
        cityId: cityId.current.value,
        price: price.current.value,
        time: time.current.value,
        text: text.current.getContent(),
        itinerary: itinerary.current.value,
        category: category.current.value,
        likes: likes.current.value,
        bestView: bestView.current.checked,
        family: family.current.checked,
        activity: activity.current.checked,
        visit: visit.current.checked,
        trek: trek.current.checked,
        meal: meal.current.checked,
        transport: transport.current.checked,
        toilet: toilet.current.checked,
        link: link.current.value,
    };

    fetch(`${API_URL}/api/spot`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(postData)
    } )
        .then(response => response.json())
        .then(response => setSpot(response))

    navigate('/spot');
  }

  useEffect(() => {
    fetchData();
  }, []);



  return (
    <div className='add'>
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1 className='title'>Add New Spot</h1>
          <div className="updateButton">
              <ArrowBackIosNewOutlinedIcon className='icon' />
              <Link 
                  to={{
                      pathname: `/spot`
                  }} 
                  className='link'
              >
                  Return to the Spot's list
              </Link>
          </div>
        </div>
        {
          loading ? (
            <Spinner className='spinner' />
          ) : (
            <div className="bottom">
              <form onSubmit={handleSumbit}>
                  <fieldset>
										<legend>Informations principales</legend>
										<label>
                      <span>Name</span>
                      <input
												name="name"
												type="text"
												placeholder='Name'
												ref={name}
                      />
										</label>
										<label>
											<span>Picture</span>
											<input
												name="picture"
												type="text"
												placeholder='Picture (URL)'
												ref={picture}
											/>
										</label>
										<label>
											<span>Price</span>
											<input
												name="price"
												type="number"
												placeholder='Price of the spot'
												ref={price}
											/>
										</label>
										<label>
											<span>Time</span>
											<input
												name="time"
												type="number"
												placeholder='Time of the spot'
												ref={time}
											/>
										</label>
										<label>
											<span>Itinerary</span>
											<input
												name="itinerary"
												type="text"
												placeholder='Itinerary (URL)'
												ref={itinerary}
											/>
										</label>
										<label>
											<span>Likes</span>
											<input
												name="likes"
												type="number"
												placeholder='Likes of the spot'
												ref={likes}
											/>
										</label>
                    <label>
												<span>Link Youtube</span>
												<input
														name="link"
														type="text"
														placeholder='Link Youtube'
														ref={link}
												/>
											</label>
										<label>
                      <span>Choose City</span>
                      {
                        loading ? (
                          <Spinner />
                        ) : (
                          <select name="city" id="city" ref={cityId}>
                            <option value="">Choose a city</option>
                            {
                              options
                            }
                          </select>
                        )
                      }
                  	</label>
										<label>
											<span>Category</span>
											<select name="category" id="category" ref={category}>
												<option value="">Choose a category</option>
												<option value="Visite">Visite</option>
												<option value="Point de vue">Point de vue</option>
												<option value="Shopping">Shopping</option>
												<option value="Découverte">Découverte</option>
												<option value="Promenade">Promenade</option>
												<option value="Plage">Plage</option>
												<option value="Parc Animalier">Parc Animalier</option>
												<option value="Parc d'Attraction">Parc d'Attraction</option>
												<option value="Restaurant">Restaurant</option>
											</select>
										</label>
									</fieldset>
                  <fieldset className='checkbox'>
										<legend>Checkbox</legend>
											<label>
												<input
														name="bestView"
														type="checkbox"
														placeholder="It's a bestview ?"
														ref={bestView}
												/> BestView
											</label>
											<label>
												<input
													name="family"
													type="checkbox"
													placeholder="It's for the family ?"
													ref={family}
												/> Family
											</label>
											<label>
												<input
													name="activity"
													type="checkbox"
													placeholder="It's an activity ?"
													ref={activity}
												/> Activity
											</label>
											<label>
												<input
													name="visit"
													type="checkbox"
													placeholder="It's a visit ?"
													ref={visit}
												/> Visit
											</label>
											<label>
												<input
													name="trek"
													type="checkbox"
													placeholder="It's a trek ?"
													ref={trek}
												/> Trek
											</label>
											<label>
												<input
													name="meal"
													type="checkbox"
													placeholder='Meal'
													ref={meal}
												/> Meal
											</label>
											<label>
												<input
													name="transport"
													type="checkbox"
													placeholder="Transport is include ?"
													ref={transport}
												/> Transport
											</label>
											<label>
												<input
													name="toilet"
													type="checkbox"
													placeholder="Toilet is include ?"
													ref={toilet}
												/> Toilet
											</label>
									</fieldset>
                  <label className='textarea'>
                      <span>Text</span>
                      {/* <textarea ref={text} name="text" placeholder='Description' /> */}
                      <Editor
												apiKey='l4grsk94s5o1x52ye4egnqncyxrftsti2ux368r3e0pqu482'
												onInit={(evt, editor) => text.current = editor}
												init={{
													height: 500,
													menubar: false,
													selector: 'textarea',
													plugins: 'lists advlist',
													toolbar: 'bullist | bold | italic | alignleft | aligncenter',
													advlist_bullet_styles: 'disc',
													content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
												}}
											/>
                  </label>
                  
                  <button type='submit'>Add</button>
              </form>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default AddSpot