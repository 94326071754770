import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Home from './screens/home/Home';
import Login from './screens/login/Login';
import List from './screens/list/List';
import Single from './screens/single/Single';
import SingleCity from './screens/single/SingleCity';
import SingleSpot from './screens/single/SingleSpot';
import SinglePark from './screens/single/SinglePark';
import Add from './screens/add/Add';
import AddCity from './screens/add/AddCity';
import AddSpot from './screens/add/AddSpot';
import AddPark from './screens/add/AddPark';
import Update from './screens/update/Update';
import UpdateCity from './screens/update/UpdateCity';
import UpdateSpot from './screens/update/UpdateSpot';
import UpdatePark from './screens/update/UpdatePark';

import './style/dark.scss'
import { useContext } from 'react';
import { DarkModeContext } from './context/darkModeContext';
import Admin from './screens/admin/Admin';
import Auth from './screens/auth/Auth';
import Profile from './screens/profile/Profile';

function App() {

  const {darkMode} = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "App dark" : "App"}>
      <BrowserRouter>
        <Routes>
          <Route path='/'>
            <Route index element={<Auth />} />
            <Route path='/admin' element={<Admin/>} />
            <Route path='/login' element={<Login/>} />
            <Route path='/dashboard' element={<Home />} />
            <Route path='/country'>
              <Route index element={<List type="country" />} />
              <Route path=':countryId'>
                <Route index element={<Single/>} />
                <Route path='update' element={<Update/>} />
              </Route>
              <Route path='add' element={<Add/>} />
            </Route>
            <Route path='/city'>
              <Route index element={<List type="city" />} />
              <Route path=':cityId'>
                <Route index element={<SingleCity />} />
                <Route path='update' element={<UpdateCity />} />
              </Route>
              <Route path='add' element={<AddCity/>} />
            </Route>
            <Route path='/spot'>
              <Route index element={<List type="spot" />} />
              <Route path=':spotId'>
                <Route index element={<SingleSpot />} />
                <Route path='update' element={<UpdateSpot />} />
              </Route>
              <Route path='add' element={<AddSpot />} />
            </Route>
            <Route path='/park'>
              <Route index element={<List type="park" />} />
              <Route path=':parkId'>
                <Route index element={<SinglePark />} />
                <Route path='update' element={<UpdatePark />} />
              </Route>
              <Route path='add' element={<AddPark />} /> 
            </Route>
            <Route path='/profile' element={<Profile />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
