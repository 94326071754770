import React, { useContext } from 'react'
import './navbar.scss'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import { DarkModeContext } from '../../context/darkModeContext';

const Navbar = () => {

  const {dispatch} = useContext(DarkModeContext);

  return (
    <div className='navbar'>
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder='Search...' />
          <SearchOutlinedIcon className="icon" />
        </div>
        <div className="items">
          <div className="item">
            <DarkModeOutlinedIcon className="icon" onClick={ () => dispatch({type: "TOGGLE"})} />
          </div>
          <div className="item">
            <FormatListBulletedOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <a href="/profile">
              <img 
                src="https://app.destinationautourdumonde.fr/assets/images/icone-destination.png" 
                alt="avatar" 
                className='avatar'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar