import React, {useState, useRef} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import './add.scss'

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import API_URL from '../../API/Api';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'


const Add = () => {

  const navigate = useNavigate();

  let [country, setCountry] = useState({})
  const name = useRef('');
  const picture = useRef('');
  const token = localStorage.getItem('token');

  const handleSumbit = (event) => {
    event.preventDefault();

    const postData = {
        name: name.current.value,
        picture: picture.current.value,
    };

    fetch(`${API_URL}/api/country`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json' ,
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(postData)
    } )
        .then(response => response.json())
        .then(response => setCountry(response))

    navigate('/dashboard');
}

  return (
    <div className='add'>
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1 className='title'>Add New Country</h1>
          <div className="updateButton">
              <ArrowBackIosNewOutlinedIcon className='icon' />
              <Link 
                  to={{
                      pathname: `/country`
                  }} 
                  className='link'
              >
                  Return to the Countrie's list
              </Link>
          </div>
        </div>
        <div className="bottom">
          <form onSubmit={handleSumbit}>
            <div className="width100">
              <label>
                  <span>Name</span>
                  <input
                      name="name"
                      type="text"
                      placeholder='Name'
                      ref={name}
                      required
                  />
              </label>
            </div>
            <div className="width100">
              <label>
                  <span>Picture</span>
                  <input
                      name="picture"
                      type="text"
                      placeholder='Picture (URL)'
                      ref={picture}
                      required
                  />
              </label>
            </div>
            <button type='submit'>Add</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Add