import React, { useContext } from 'react'
import './sidebar.scss'

import DashboardIcon from '@mui/icons-material/Dashboard';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import ParkOutlinedIcon from '@mui/icons-material/ParkOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { DarkModeContext } from '../../context/darkModeContext';
import { useNavigate } from 'react-router-dom';


const Sidebar = () => {

  const navigate = useNavigate();
  const {dispatch} = useContext(DarkModeContext);

  const logout = () => {
    localStorage.removeItem("token");
    navigate('/');
  }

  return (
    <div className='sidebar'>
      <div className="top">
        <span className="logo">Destination</span>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">Main</p>
          <li>
            <DashboardIcon className="icon" />
            <a href="/dashboard"><span>Dashboard</span></a>
          </li>
          <p className="title">Lists</p>
          <li>
            <PublicOutlinedIcon className="icon" type="country" />
            <a href="/country"><span>Countries</span></a>
          </li>
          <li>
            <ApartmentOutlinedIcon className="icon" type="city" />
            <a href="/city"><span>Cities</span></a>
          </li>
          <li>
            <RoomOutlinedIcon className="icon" />
            <a href="/spot"><span>Spots</span></a>
          </li>
          <li>
            <ParkOutlinedIcon className="icon" />
            <a href="/park"><span>Parks</span></a>
          </li>
          <p className="title">Service</p>
          <li>
            <SettingsOutlinedIcon className="icon" />
            <span>Settings</span>
          </li>
          <p className="title">User</p>
          <li>
            <ManageAccountsOutlinedIcon className="icon" />
            <a href="/profile"><span>Profile</span></a>
          </li>
          <li>
            <LoginOutlinedIcon className="icon" />
            <span onClick={logout}>Logout</span>
          </li>
        </ul>
      </div>
      <div className="bottom">
        <div className="colorOption" onClick={() => dispatch({type:'LIGHT'}) }></div>
        <div className="colorOption" onClick={() => dispatch({type:'DARK'}) }></div>
        {/* <div className="colorOption"></div> */}
      </div>
    </div>
  )
}

export default Sidebar