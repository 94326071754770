import React, { useState, useRef, useEffect } from 'react'

import './login.scss'

import API_URL from '../../API/Api'
import { useNavigate } from 'react-router-dom'

const Login = () => { 

  const navigate = useNavigate();
  const token = localStorage.getItem('token')

  const [user, setUser] = useState();
  const email = useRef('');
  const password = useRef('');


  const handleSumbit = (event) => {
    event.preventDefault();

    const postData = {
      username: email.current.value,
      password: password.current.value,
    };
    //console.log(postData)

    fetch(`${API_URL}/api/login`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json' ,
        },
        mode: 'cors',
        body: JSON.stringify(postData)
    } )
        .then(response => response.json())
        .then(response => {
          setUser(response)
          //console.log(response)
          if(response.code === 400 || response.code === 401 || response.code === 404)
          {
            console.log(`erreur ${response.code}`)
          }
          else 
          {
            localStorage.setItem("token", response.token)
            navigate('/dashboard')
          }
          
        })
        .catch(error => console.log(error))
  }

  return (
    <div className="login">
      <form onSubmit={handleSumbit}>
        <label>
          <span>Email</span>
          <input
            type="email"
            placeholder="john@doe.com"
            name='email'
            ref={email}
          />
        </label>
        <label>
          <span>Password</span>
          <input
            type="password"
            placeholder="Password"
            name='password'
            ref={password}
          />
        </label>
        <button type="submit" className="">
          Submit
        </button>
      </form>
    </div>
  )
}

export default Login