import React, {useState, useEffect} from 'react'
import './datatable.scss'

import API_URL from '../../API/Api';

import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";



const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Name', width: 230 },
  { 
      field: 'picture', 
      headerName: 'Picture', 
      width: 300,
      renderCell: (params) => {
          return(
            <img 
              src={params.row.picture} 
              alt={params.row.name} 
            />
          )
      }
  },
  {
    field: "action",
    headerName: "Action",
    width: 350,
    renderCell: (params) => {
        return(
          <div className="cellAction">
              <div className="viewButton">
                <Link 
                    to={{
                        pathname: `/park/${params.row.id}`
                    }} 
                    className='link'
                >
                 View
                </Link>
              </div>
              <div className="updateButton">
                <Link 
                    to={{
                        pathname: `/park/${params.row.id}/update`
                    }} 
                    className='link'
                >
                 Update
                </Link>
              </div>
              <div className="deleteButton" onClick={(event) => {
                event.preventDefault();

                let res = window.confirm("Etes vous sur de supprimer l'élément ?");
                //console.log(res)
                if (res === true ) 
                {
                  fetch(`${API_URL}/api/park/${params.row.id}`, {
                      method: 'DELETE'
                  } )
                      .then(response => response.json())

                  window.location.reload();
                }
                
              }}>
                Delete
              </div>
          </div>
        )
    }
  },
];

const DatatablePark = () => {

  const [rows, setRows] = useState(true);
  const token = localStorage.getItem('token');

  const fetchData = () => {
    fetch(`${API_URL}/api/parks`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(response => response.json())
      .then(response => setRows(response))
      .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchData();
  }, [])


  return (
    <div className='datatable'>
      <a href="/park/add" className='addLink'>
        Add Park
      </a>
      <DataGrid
        className='datagrid'
        rows={rows}
        columns={columns}
        pageSize={7}
        rowsPerPageOptions={[7]}
        checkboxSelection
      />
    </div>
  )
}

export default DatatablePark