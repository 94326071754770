import React, {useState, useEffect} from 'react'
import { useParams, Link } from 'react-router-dom';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import './single.scss'

import API_URL from '../../API/Api';

import Spinner from "react-activity/dist/Dots";
import "react-activity/dist/library.css";
import parse from 'html-react-parser';

const SinglePark = () => {
  let { parkId } = useParams();

  const [park, setPark] = useState([]);
  let [loading, setLoading] = useState(true)
  const token = localStorage.getItem('token')
  
  const fetchData = () => {
    setLoading(true)
    fetch(`${API_URL}/api/park/${parkId}`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(response => response.json())
      .then(response => setPark(response))
      .catch(error => console.log(error))
      .finally( () => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData();
}, [])

  return (
    <div className='single'>
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        {
          loading ? (
            <Spinner className='spinner' />
          ) : (
            <div className="top">
              <div className="editButton">
                <Link
                  to={{
                    pathname: `update`
                  }}
                  className='link'
                >
                  Edit
                </Link>
              </div>

              <h1 className="title">Information on {park.name}</h1>

              <div className="return">
                <Link
                  to={{
                    pathname: `/park`
                  }}
                  className='link'
                >
                  Return to the park's list
                </Link>
              </div>
              
              <div className="item">
                <img 
                  src={park.picture}
                  alt={park.name} 
                  className="itemImg" 
                />
                <div className="details">
                  <div className="detail">
                    <h2 className="itemTitle">{park.name}</h2>
                    <p>{park.country?.name}</p>
                    <p><strong>Price</strong> : {park.price} €</p>
                    <p><strong>Time</strong> : {park.time} minutes</p>
                    <p><strong>Catégorie</strong> : {park.category}</p>
                    <p><a href={park.itinerary} target="_blank" rel='noreferrer' className='link'>Itinerary</a></p>
                  </div>
                  
                  <div className="checkbox">
                    <p><strong>Bestview</strong> : {park.bestView === true ? '✅' : '❌'}</p>
                    <p><strong>Family</strong> : {park.family === true ? '✅' : '❌'}</p>
                    <p><strong>Activity</strong> : {park.activity === true ? '✅' : '❌'}</p>
                    <p><strong>Visit</strong> : {park.visit === true ? '✅' : '❌'}</p>
                    <p><strong>Trek</strong> : {park.trek === true ? '✅' : '❌'}</p>
                    <p><strong>Meal</strong> : {park.meal === true ? '✅' : '❌'}</p>
                    <p><strong>Transport</strong> : {park.transport === true ? '✅' : '❌'}</p>
                    <p><strong>Toilet</strong> : {park.toilet === true ? '✅' : '❌'}</p>
                  </div>

                  <div className="description">
                    {/* <p>{park.text}</p> */}
                    {parse(`${park.text}`)}
                  </div>

                  <div className='youtube'>
                    {
                      park.link !== ''
                      ?
                        <p>{park.link}</p>
                      :
                        ''
                    }
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default SinglePark