import React, {useState, useRef, useEffect} from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom';
import './update.scss'

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

import API_URL from '../../API/Api';

import Spinner from "react-activity/dist/Dots";
import "react-activity/dist/library.css";

const UpdateCity = () => {
  const navigate = useNavigate();

  let { cityId } = useParams();

  let [city, setCity] = useState({});
  let [loading, setLoading] = useState(true);
  let [options, setOptions] = useState();
  const token = localStorage.getItem('token');
  const name = useRef();
  const picture = useRef();
  const countryId = useRef();

  const getCountriesOptions = (countries) => {
    const countriesOptions = countries.map(item => {
        return (<option key={item.id} value={item.id}>{item.name}</option>)
    });
    return countriesOptions;
  }

  const fetchData = () => {
    setLoading(true)
    fetch(`${API_URL}/api/city/${cityId}`, {
        method: 'GET',
        headers : {
          'Authorization': 'Bearer ' + token,
        }
    })
      .then(response => response.json())
      .then((response) => {
          setCity(response);
      })
      .catch(error => console.log(error))
      .finally(() => {
          setLoading(false);
      });
    fetch(`${API_URL}/api/countries`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(response => response.json())
      .then((response) => {
          const cityOptions = getCountriesOptions(response);
          setOptions(cityOptions);
      })
      .catch(error => console.log(error))
      .finally(() => {
          setLoading(false);
      });
}

  const handleSumbit = (event) => {
    event.preventDefault();

    const postData = {
      name: name.current.value,
      picture: picture.current.value,
      countryId: countryId.current.value,
    };

    fetch(`${API_URL}/api/city/${cityId}`, {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(postData)
    } )
      .then(response => response.json())
      .then(response => setCity(response))

    navigate('/city');
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='update'>
        <Sidebar />
        <div className="updateContainer">
            <Navbar />
            {
                loading ? (
                    <Spinner className='spinner' />
                ) : (
                    <div className="form">
                        <div className="top">
                            <h1 className="title">Update {city.name}</h1>
                            <div className="updateButton">
                                <ArrowBackIosNewOutlinedIcon className='icon' />
                                <Link 
                                    to={{
                                        pathname: `/city`
                                    }} 
                                    className='link'
                                >
                                    Return to the Citie's list
                                </Link>
                            </div>
                        </div>
                        <form onSubmit={handleSumbit}>
                            <label>
                                <span>Name</span>
                                <input
                                    name="name"
                                    type="text"
                                    placeholder='Name'
                                    defaultValue={city.name}
                                    ref={name}
                                />
                            </label>
                            <label>
                                <span>Picture</span>
                                <input
                                    name="picture"
                                    type="text"
                                    placeholder='Picture (URL)'
                                    defaultValue={city.picture}
                                    ref={picture}
                                />
                            </label>
                            <label>
                                <span>Choose Country</span>
                                {
                                    loading ? (
                                    <Spinner />
                                    ) : (
                                    <select name="" id="" ref={countryId}>
                                        <option value="">Choose a country</option>
                                        {
                                        options
                                        }
                                    </select>
                                    )
                                }
                            </label>
                            <button type='submit'>Update</button>
                        </form>
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default UpdateCity