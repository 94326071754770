import React, { useState, useEffect } from 'react'
import './profile.scss'

import API_URL from '../../API/Api';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';

function Profile() {

  const[users, setUsers] = useState({});
  const token = localStorage.getItem('token');

  const fetchData = () => {
    fetch(`${API_URL}/api/users`, {
        method: 'GET',
        headers : {
          'Authorization': 'Bearer ' + token,
        }
      })
        .then(response => response.json())
        .then(response => setUsers(response[0]))
        .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div className="profile">
      <Sidebar />
      <div className="profileContainer">
        <Navbar />
        <div className="top">
            <h1 className="title">Profile</h1>
        </div>

        <div className="bottom">
            <div className="items">
              <p>
                Email : {users.email}
              </p>
              <p>
                Rôle : {users.roles}
              </p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Profile