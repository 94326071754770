import React from 'react'
import './list.scss'

import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import Datatable from '../../components/datatable/Datatable'
import DatatableCity from '../../components/datatable/DatatableCity'
import DatatableSpot from '../../components/datatable/DatatableSpot'
import DatatablePark from '../../components/datatable/DatatablePark'

const List = ({ type }) => {
  let data;

  switch(type)
  {
    case 'country':
      data= <Datatable />
      break;
    case 'city':
      data= <DatatableCity />
      break;
    case 'spot':
      data= <DatatableSpot />
      break;
    case 'park':
      data= <DatatablePark />
      break;
    default:
      break;
  }

  return (
    <div className='list'>
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        {data}
      </div>
    </div>
  )
}

export default List