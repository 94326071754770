import React, {useState, useEffect} from 'react'
import { useParams, Link } from 'react-router-dom';

import API_URL from '../../API/Api';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

import './single.scss'

const Single = () => {
  let { countryId } = useParams();

  const [country, setCountry] = useState([]);
  const token = localStorage.getItem('token');

  const fetchData = () => {
    fetch(`${API_URL}/api/country/${countryId}`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(response => response.json())
      .then(response => setCountry(response))
      .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div className='single'>
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
            <div className="editButton">
              <Link
                to={{
                  pathname: `update`
                }}
                className='link'
              >
                Edit
              </Link>
            </div>

            <h1 className="title">Information on {country.name}</h1>

            <div className="return">
              <Link
                to={{
                  pathname: `/country`
                }}
                className='link'
              >
                Return to the countrie's list
              </Link>
            </div>
            
            <div className="item">
              <img 
                src={country.picture}
                alt="test" 
                className="itemImg" 
              />
              <div className="details">
                <h2 className="itemTitle">{country.name}</h2>
              </div>
            </div>
        </div>
        <div className="bottom"></div>
      </div>
    </div>
  )
}

export default Single