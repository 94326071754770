import React, {useState, useEffect} from 'react'
import './widget.scss'

import API_URL from '../../API/Api'

import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import ParkOutlinedIcon from '@mui/icons-material/ParkOutlined';

const Widget = ({ type }) => {

  const [all, setAll] = useState(true);
  const token = localStorage.getItem('token');

    useEffect(() => {
        fetchData();
    }, [])
  
    const fetchData = () => {
        fetch(`${API_URL}/api/all`, {
          method: 'GET',
          headers : {
            'Authorization': 'Bearer ' + token,
          }
        })
          .then(response => response.json())
          .then(response => setAll(response))
          .catch(error => console.log(error))
    }

  let data;

  switch(type)
  {
      case 'country':
        data={
            title: 'Countries',
            counter: all.Countries?.length,
            link: (
              <a href="/country" className='link'>
                See All Countries
              </a>
            ),
            icon: <PublicOutlinedIcon className='icon' style={{
                color:"rgba(247, 120, 94, 1)", 
                backgroundColor: "rgba(247, 120, 94, 0.3)",
            }} />,
        };
        break;
      case 'city':
        data={
            title: 'Cities',
            counter: all.Cities?.length,
            link: (
              <a href="/city" className='link'>
                See All Cities
              </a>
            ),
            icon: <ApartmentOutlinedIcon className='icon' style={{
                color:"rgba(222, 218, 0, 1)", 
                backgroundColor: "rgba(222, 218, 0, 0.3)",
            }} />,
        };
        break;
      case 'spot':
        data={
            title: 'Spots',
            counter: all.Spots?.length,
            link: (
              <a href="/spot" className='link'>
                See All Spots
              </a>
            ),
            icon: <RoomOutlinedIcon className='icon' style={{
                color:"rgba(255, 45, 248, 1)", 
                backgroundColor: "rgba(255, 45, 248, 0.3)",
            }} />,
        };
        break;
      case 'park':
        data={
            title: 'Parks',
            counter: all.Parks?.length,
            link: (
              <a href="/park" className='link'>
                See All Parks
              </a>
            ),
            icon: <ParkOutlinedIcon className='icon' style={{
                color:"rgba(11, 221, 0, 1)", 
                backgroundColor: "rgba(11, 221, 0, 0.3)",
            }} />,
        };
        break;
      default: 
        break;
  }

  return (
    <div className="widget">
        <div className="left">
            <div className="title">{data.title}</div>
            <div className="counter">{data.counter}</div>
            <div className="link">{data.link}</div>
        </div>
        <div className="right">
            <div className="percentage positive">
                <KeyboardArrowUpOutlinedIcon />
                20%
            </div>
            {data.icon}
        </div>
    </div>
  )
}

export default Widget